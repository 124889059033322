import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import { Image } from 'components/Shared/Image'
import ProgramNav from 'components/Public/ProgramNav'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  p {
    --text-align: left;
  }
  .gatsby-image-wrapper {
    width: clamp(35rem, 65vw, 70rem);
    margin: 0 auto;
    border-radius: 1rem;
    margin-bottom: clamp(5rem, 6vw, 8rem);
  }
`
const Content = styled.div`
  width: var(--paragraph-width);
  margin: 0 auto;
  --text-align: left;

  h3 {
    margin: var(--margin-default) 0 calc(var(--margin-default) * 0.25);
    color: var(--color-primary);
  }
`
const Test = styled.ul`
  text-align: left;
  margin-left: 2rem;
  li {
    line-height: 1.5;
  }
`
const Schedule = styled.div`
  margin-left: 2rem;
  p {
    line-height: 1.5;
    --margin-top: 0;
    --margin-bottom: 0;

    &:last-child {
      --margin-bottom: 2rem;
    }
  }
  &:last-child{
    margin-bottom: calc(var(--margin-default)*2.5);
  }

`
//#endregion

const ProgramDetail = ({ data, location }) => {
  location.pathname = '/programs'

  const {
    mdx: {
      frontmatter: { title, test, schedules },
      body,
    },
    imageFile: { childImageSharp: image },
  } = data

  return (
    <PageContent>
      <Seo title={title} description={`${title} description`} />
      <ProgramNav location={location} />
      <Title>{title}</Title>
      <Wrapper>
        <Image image={image} alt={title} />
        <Content>
        <MDXRenderer>{body}</MDXRenderer>
        <h3>甄試</h3>
        <Test>
          {test.map((t,i) => (
            <li key={`test-${i}`}>{t}</li>
          ))}
        </Test>
        <h3>練唱時間</h3>
        {schedules.map((schedule,i) => (
          <Schedule key={`schedule-${i}`}>
            {schedule.name && <h4>{schedule.name}</h4>}
            <p>{schedule.place}</p>
            <p>{schedule.time}</p>
          </Schedule>
        ))}
        </Content>
      </Wrapper>
    </PageContent>
  )
}

export default ProgramDetail

export const query = graphql`
  query($slug: String!, $css: String) {
    mdx(frontmatter: { type: { eq: "program" }, slug: { eq: $slug } }) {
      frontmatter {
        title
        test
        schedules {
          name
          place
          time
        }
      }
      body
    }

    imageFile: file(name: { eq: $css }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`
